import React, { useState } from 'react';

import BaseLayout from '../components/templates/BaseLayout';
import Spacer from '../components/atoms/utility/Spacer';
import SEO from '../components/atoms/utility/seo';
import CommunityCardList from '../components/organisms/CommunityCardList';
import useFetchCommunities from '../utils/hooks/useFetchCommunities';

export default function CommunityPage({ data }) {
  const [searchField, setSearchField] = useState('');
  const { communitiesWithActivity: communitiesToDisplay, isLoading } = useFetchCommunities({
    searchKeyword: searchField,
  });

  return (
    <BaseLayout
      mainSectionClass="z-20 relative flex-grow h-full w-full"
      hideHeroImage
      customHeaderClass=" "
    >
      <SEO
        title="Discover Communities & Local Events | Connect & Explore with Flockx"
        description={"Dive into the heart of your interests with Flockx. From real-time community events to niche meetups, find and participate in gatherings that fuel your passion. Connect with community hubs, meet like-minded individuals, and join the vibrant tapestry of communities shaping the culture of your city."}
      />
      <Spacer sizeY={1} className="block md:hidden" />
      <div className="pt-10 lg:pt-20 px-8 md:px-32">
        <div className="text-gray-900 text-lg md:text-6xl text-center md:text-left font-bold font-['DM Sans']">
          Discover Communities
        </div>

        <div className="w-full mt-4 md:mt-8 justify-start items-start gap-2 inline-flex">
          <input
            id="search"
            type="search"
            placeholder="Search community"
            value={searchField}
            autoCapitalize={false}
            autoComplete={false}
            onChange={(e) => setSearchField(e.target.value)}
            className="w-full h-11 md:w-[431px] md:h-[52px] md:px-[18px] bg-zinc-100 rounded-2xl block rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div className="pt-10 px-8 md:px-32">
        <CommunityCardList
          communitiesToDisplay={communitiesToDisplay}
          isLoading={isLoading}
        />
      </div>

      <Spacer sizeY={1} />
    </BaseLayout>
  );
}
