export const extractSources = (arr) => {
  const sources = [];
  for (let i = 0; i < arr.length; i++) {
    sources.push(arr[i]._source);
  }
  return sources;
};

export const createFeatureCollection = (features) => ({
  type: 'FeatureCollection',
  features
});

export const ELASTICSEARCH = {
  INDEX: {
    FESTIVAL: 'festival_index',
    COMMUNITIES: 'community_index',
    POI: 'point_of_interest_index',
    ACTIVITIES: {
      GET: 'activity_index',
      STREETS_GLOBAL: 'street_activity_aggregated_global'
    }
  }
};

export const communitiesQuery = {
  index: ELASTICSEARCH.INDEX.COMMUNITIES,
  query: {
    match_all: {}
  },
  size: 100
};

export const getCommunityAggregationQuery = ({ searchValue, perPage = 10 }) => {
  let range = {
    'properties.end_time_utc': {
      gte: 'now-4h',
    },
  };

  let query = {
    index: ELASTICSEARCH.INDEX.ACTIVITIES.GET,
    query: {
      bool: {
        must: [
          {
            range,
          },
        ],
      },
    },
    aggs: {
      communities: {
        terms: {
          field: 'properties.community.keyword',
          size: 20,
        },
        aggs: {
          top_field_hits: {
            top_hits: {
              _source: {
                includes: [
                  'properties.community',
                  'properties.community_name',
                  'properties.community_image_url',
                  'properties.community_description',
                ],
              },
              size: 1,
            },
          },
        },
      },
    },
    size: 0,
  };

  if (searchValue?.length) {
    query.query.bool.must.push({
      multi_match: {
        query: searchValue,
        type: 'phrase',
        fields: [
          'properties.community_name',
          'properties.community_description',
        ],
      },
    });
  }
  return query;
};

export const getFestivalCommunitiesAggregationQuery = ({
  festivalId,
  perPage = 10,
}) => {
  let query = {
    index: ELASTICSEARCH.INDEX.ACTIVITIES.GET,
    query: {
      bool: {
        must: {
          match: {
            'properties.festivals': `${[festivalId]}`,
          },
        },
      },
    },
    aggs: {
      communities: {
        terms: {
          field: 'properties.community.keyword',
          size: perPage,
        },
        aggs: {
          top_field_hits: {
            top_hits: {
              _source: {
                includes: [
                  'properties.community',
                  'properties.community_name',
                  'properties.community_image_url',
                  'properties.community_description',
                  'properties.community_banner_url',
                ],
              },
              size: 1,
            },
          },
        },
      },
    },
    size: 0,
  };
  return query;
};

export const getPoiCommunitiesAggregationQuery = ({ poiId, perPage = 10 }) => {
  let query = {
    index: ELASTICSEARCH.INDEX.ACTIVITIES.GET,
    query: {
      bool: {
        must: {
          match: {
            'properties.poi.keyword': poiId,
          },
        },
      },
    },
    aggs: {
      communities: {
        terms: {
          field: 'properties.community.keyword',
          size: perPage,
        },
        aggs: {
          top_field_hits: {
            top_hits: {
              _source: {
                includes: [
                  'properties.community',
                  'properties.community_name',
                  'properties.community_image_url',
                  'properties.community_description',
                  'properties.community_banner_url'
                ],
              },
              size: 1,
            },
          },
        },
      },
    },
    size: 0,
  };
  return query;
};

export const getPoiAggregationQuery = ({ perPage = 10, city }) => {
  let range = {
    'properties.start_time_utc': {
      gte: 'now-4h',
    },
  };

  let searchQuery = {
    index: ELASTICSEARCH.INDEX.ACTIVITIES.GET,
    query: {
      bool: {
        must: [
          {
            range,
          },
        ],
      },
    },
    aggs: {
      poi: {
        terms: {
          field: 'properties.poi.keyword',
          size: perPage,
        },
        aggs: {
          community: {
            terms: {
              field: 'properties.community.keyword',
              size: 5,
            },
            aggs: {
              top_field_hits: {
                top_hits: {
                  _source: {
                    includes: [
                      'properties.community',
                      'properties.community_name',
                      'properties.community_image_url',
                      'properties.community_description',
                      'properties.community_banner_url',
                      'properties.point_of_interest_name',
                      'properties.poi',
                      'properties.street',
                      'properties.city',
                      'properties.state',
                    ],
                  },
                  size: 1,
                },
              },
            },
          },
        },
      },
    },
    size: 0,
  };

  if (city) {
    searchQuery.query.bool.must.push({
      match: {
        'properties.city': city,
      },
    });
  }
  return searchQuery;
};

export const latestLeadingPostQuery = {
  index: ELASTICSEARCH.INDEX.ACTIVITIES.GET,
  query: {
    bool: {
      must: [
        // activity must have community attached
        { exists: { field: 'properties.community' } },
        // activity must be leading
        {
          match: {
            'properties.category': 'leading'
          }
        },
        {
          range: {
            'properties.created': {
              gte: 'now-4h' // Within the last 4hrs
            }
          }
        }
      ]
    }
  },
  sort: [
    {
      'properties.created': {
        numeric_type: 'date',
        order: 'desc'
      }
    }
  ],
  size: 1
};
