import { useEffect, useState } from 'react';
import { extractSources } from '../helpers/elastic-search';

export default function useFetchCommunities(params) {
  const { searchKeyword } = params || {};
  const [communitiesWithActivity, setCommunitiesWithActivity] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchCommunities() {
      try {
        setIsLoading(true);
        let url = '/.netlify/functions/es-community-aggregation/';
        if (searchKeyword) {
          url += `?search_value=${searchKeyword}`;
        }
        const res = await fetch(url);
        const data = await res.json();
        setCommunitiesWithActivity(data);
        const response = await fetch(`/.netlify/functions/es-communities/`);
        const data1 = await response.json();
        setCommunities(extractSources(data1));
        setTimeout(() => setIsLoading(false), 500);
      } catch (error) {
        console.log('Error in fetchCommunities: ', error.message);
        setIsLoading(false);
      }
    }

    fetchCommunities();
  }, [searchKeyword]);

  return { communitiesWithActivity, communities, isLoading };
}
