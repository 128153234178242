import React, {  } from 'react';
import GlobeIcon from '../atoms/icons/GlobeIcon';
import ChevronRight from '../atoms/icons/ChevronRight';
import Spacer from '../atoms/utility/Spacer';
import { navigate } from 'gatsby';


const SkeletonActivityCards = () => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((a) => (
    <div
      key={a}
      className="animate-pulse skeleton group bg-white rounded-2xl border"
    >
      <div className="relative aspect-h-1 aspect-w-1 overflow-hidden bg-gray-200 rounded-tl-2xl rounded-tr-2xl rounded-br-0 rounded-bl-0">
        <div className="w-full h-[116px] md:h-[185px] object-cover object-center" />

        <div className="bottom-3 right-3 absolute items-center gap-1.5 inline-flex">
          <div className="w-[60px] h-6 md:h-8 bg-gray-400 rounded-[60px]" />
        </div>
      </div>
      <div className="py-[18px] px-4 sm:px-6 flex-col justify-start items-start gap-4 inline-flex">
        <div className="w-[250px] h-6 md:h-8 bg-gray-200 rounded-[60px]" />
        <div className="w-[200px] h-4 md:h-6 bg-gray-200 rounded-[60px]" />
        <div className="w-[200px] h-4 md:h-6 bg-gray-200 rounded-[60px]" />
      </div>
    </div>
  ));
};

const EmptyState = () => {
  return (
    <div className="-mx-px sm:mx-0 mt-4 w-full px-2 py-4 bg-grey-gradient rounded-2xl backdrop-blur-[34px] flex-col justify-center items-center gap-4 inline-flex">
      <div className="justify-center items-center gap-1.5 inline-flex">
        <GlobeIcon className="w-[18px] h-[18px] md:w-6 md:h-6 relative" />
        <div className="text-gray-900 text-base md:text-2xl font-bold font-['DM Sans'] leading-tight">
          Keep Exploring
        </div>
      </div>
      <div className="text-center text-gray-600 text-sm md:text-base font-normal font-['DM Sans'] leading-none">
        There are no activities in your local community right now. Try exploring
        other communities to discover more activities.
      </div>
    </div>
  );
};

const CommunityCardList = ({ communitiesToDisplay, isLoading, isVisible = true }) => {
  const handleCommunityClick = (community) => {
    const slug = community.name.toLowerCase().replaceAll(' ', '-');
    navigate(`/community/${slug}`);
  };

  if (!isVisible) return null;
  return (
    <>
      {!isLoading && communitiesToDisplay?.length === 0 && <EmptyState />}
      <div className="-mx-px sm:mx-0 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
        {isLoading && <SkeletonActivityCards />}
        {!isLoading &&
          communitiesToDisplay?.map((community) => (
            <div
              className="cursor-pointer w-full p-4 bg-grey-gradient rounded-2xl flex-col justify-start items-start gap-3 inline-flex"
              onClick={() => handleCommunityClick(community)}
            >
              <div className="flex-col justify-start items-start gap-3 flex">
                <div className="justify-between items-center gap-3 inline-flex w-full">
                  <div className="justify-start items-center gap-3 flex">
                    <img
                      src={community.image_url}
                      alt={community.name}
                      title={community.name}
                      className="w-[35px] h-[35px] md:w-[42px] md:h-[42px] object-cover object-center"
                    />
                    <div className="justify-start items-center gap-3 flex">
                      <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                        <div className="text-gray-900 text-base md:text-lg font-bold font-['DM Sans'] leading-tight">
                          {community.name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <ChevronRight className="w-4 h-4 md:w-6 md:h-6" />
                </div>
                <div className="text-slate-800 text-xs md:text-base font-normal font-['DM Sans']">
                  {community.description}
                </div>
              </div>
            </div>
          ))}
        <Spacer sizeY={1} />
      </div>
    </>
  );
};

export default CommunityCardList;
