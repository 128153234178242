import React from 'react';

export default function Spacer({ sizeX = 2, sizeY = 2, className = '' }) {
  let xPadding = '',
    yPadding = '';

  if (sizeY === 2) {
    yPadding = 'pt-10 sm:pt-20 md:pt-40 xl:pt-60';
  } else if (sizeY === 1) {
    yPadding = 'pt-10 md:pt-20 xl:pt-32';
  } else if (sizeY === 3) {
    yPadding = 'pt-20 md:pt-40 xl:pt-64';
  }

  return <div className={xPadding + ' ' + yPadding + ' ' + className}></div>;
}
